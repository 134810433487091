<template>
  <div v-if="Object.keys(promotion).length" class="cws-content">
    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
            :img-src="promotion.img"
            :title="promotion.title"
            img-top
            img-alt="Blog Detail Pic"
          >
            <div class="my-1 py-25">
              <b-link
                v-for="category in promotion.categories"
                :key="category.id"
                :to="'/announcements/' + category.id"
              >
                <b-badge :variant="tagsColor(category.id)" pill class="mr-75">
                  {{ category.title }}
                </b-badge>
              </b-link>
            </div>
            <!-- eslint-disable vue/no-v-html -->
            <div class="blog-content" v-html="promotion.description" />
            <hr class="my-2" />

            <b-button
              :disabled="promotion.participated"
              variant="primary"
              @click="promotionParticipate"
            >
              {{
                promotion.participated
                  ? $t('You have already participated in this promotion')
                  : $t('Participate')
              }}
            </b-button>

            <b-button
              v-if="promotion.participated"
              variant="danger"
              class="ml-2"
              @click="promotionUnparticipate"
            >
              {{ $t('Unparticipate') }}
            </b-button>
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->
  </div>
</template>

<script>
import axios from '@/libs/axios'
import { BLink, BCard, BRow, BCol, BBadge, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { blogUtils } from '@core/mixins/ui/blog'

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [blogUtils],
  data() {
    return {
      promotion: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  created() {
    this.fetchEntity()
    this.$watch('id', () => {
      this.fetchEntity()
    })
  },
  methods: {
    fetchEntity() {
      axios.get(`promotions/${this.id}`).then(res => {
        this.promotion = res.data
      })
    },
    promotionParticipate() {
      this.promotion.participated = true

      axios.get(`promotions/participate/${this.promotion.id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Entity Saved'),
            icon: 'CheckIcon',
            variant: 'success',
            text: this.$t('You have participated in the promotion'),
          },
        })
      })
    },

    promotionUnparticipate() {
      this.promotion.participated = false

      axios.get(`promotions/unparticipate/${this.promotion.id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Entity Saved'),
            icon: 'CheckIcon',
            variant: 'success',
            text: this.$t('You have unparticipated in the promotion'),
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
